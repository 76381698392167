import { breakpointsTailwind } from '@vueuse/core';

export const useCssBreakpoints = () => {
  const { smaller, smallerOrEqual, greater, greaterOrEqual } =
    useBreakpoints(breakpointsTailwind);
  const isMobile = smallerOrEqual('sm');
  const isTablet = smallerOrEqual('md');

  return {
    smaller,
    smallerOrEqual,
    greater,
    greaterOrEqual,
    isMobile,
    isTablet,
  };
};
